// 官方文档连接 https://formatjs.io/docs/core-concepts/icu-syntax#plural-format

// TODO 用繁体中文替换
export default {
  // 页面title
  'index.title': 'TrustIn',
  'index.notice.public': '公告',
  'myPage.title': '我的 | TrustIn',
  'home.title': '首頁',
  'me.title': '我的',

  // 通用文案
  day: '天',
  days: '天',
  year: '年',
  connectWallet: '連接錢包',
  networkError: '網絡錯誤',
  all: '全部',
  copy: '複製',
  copySuccess: '複製成功',
  myAddress: '我的地址',
  checkInChainExplorer: '在{explorer}查看',
  disconnect: '斷開連接',
  check: '查看',
  currency: '幣價',
  market: '市場',
  search: '搜索',
  mint: '存入',
  redeem: '取款',
  borrow: '借款',
  repay: '還款',
  claim: '領取收益',
  mint_v: '存入',
  redeem_v: '取款',
  borrow_v: '借款',
  repay_v: '還款',
  enterMarket: '開啟抵押',
  exitMarket: '關閉抵押',
  approve: '授權',
  success: '成功',
  fail: '失敗',
  mintRedeem: '存取',
  borrowRepay: '借還',
  cancel: '取消',
  noData: '暫無數據',

  remainingInvestableIsInsufficient: '輸入金額超出錢包可用餘額',
  remainingInvestableIsInsufficient1: '輸入金額超出市場可用餘額',
  remainingInvestableIsInsufficient2: '輸入金額大於剩餘可借',
  remainingInvestableIsInsufficient3: '輸入金額大於存款數量',
  remainingInvestableIsInsufficient4: '輸入金額大於借款數量',
  theInputAmountExceedsTheMaximumSafeValue: '輸入金額超出最大安全值',
  availableBalance: '可用餘額',

  // 禁止使用彈窗
  'forbidden.modal.title': '提示',
  'forbidden.modal.text': '很抱歉，我們的服務不支持您所在的國家/地區!',
  'forbidden.modal.btn': '我知道了',

  // 尚未存款彈窗
  'mortgage-switch.modal.title': '尚未存款',
  'mortgage-switch.modal.text.normal': '您尚未存款，無法開啟抵押',
  'mortgage-switch.modal.text.bold': '請先存款',
  'mortgage-switch.modal.deposit': '立即存款',
  'mortgage-switch.modal.depositing': '存款中',
  'mortgage-switch.modal.withdraw': '立即取款',
  'mortgage-switch.modal.withdrawing': '取款中',
  'mortgage-switch.modal.borrow': '立即借款',
  'mortgage-switch.modal.borrowing': '借款中',
  'mortgage-switch.modal.repay': '立即還款',
  'mortgage-switch.modal.repaying': '還款中',

  // 确认关闭抵押弹窗
  'market.modal.mortgage-close-modal.content1':
    '您關閉抵押後健康度將達到 {healthValue} ，可能觸發清算',
  'market.modal.mortgage-close-modal.content2': '請再次確認是否關閉抵押',
  'market.modal.mortgage-close-modal.confirm': '確認關閉',
  'market.modal.mortgage-close-modal.closing': '關閉中',

  // 健康度低于1，无法关闭弹窗
  'market.modal.mortgage-can-not-close-modal.content':
    '取消抵押后健康度过低，当前暂不支持取消抵押',

  // Meket操作彈窗
  'market.modal.residue': '剩餘可借',
  'market.modal.totalCash': '市場可用餘額',
  'market.modal.myDeposited': '我的存款',
  'market.modal.estimatedInterestOnBorrowing': '借款預估利息',
  'market.modal.healthChange': '健康度變化',
  'market.modal.estimatedAnnualizedReturnOnDeposits': '存款预估年化收益',
  'market.modal.estimatedDailyDecreaseInEarningsAfterWithdrawal':
    '取款後預估每日減少收益',
  'market.modal.liquidationText':
    '您輸入的當前數量有可能觸发清算，請慎重考慮，點擊我已知曉再繼續操作。',
  'market.modal.iHaveKnown': '我已知曉',

  // 授权操作
  'market.modal.approve.operationAfterAuthorization': '授權後操作',
  'market.modal.approve.approving': '授權中',

  // 首页
  'home.overview.tvl': '項目TVL',
  'home.overview.amountOfDeposit': '總存款量',
  'home.overview.amountOfBorrowing': '總借款量',
  'home.overview.evenTheFluxOfTaiwanDollar': '平台幣流通量',
  'home.market.whichIAmInvolved': '我參與的',
  'home.market.table.title.assets': '資產',
  'home.market.table.title.depositApy': '存款APY',
  'home.market.table.title.leanApy': '借款APY',
  'home.market.table.title.myLoans': '我的借款',
  'home.market.table.title.amountOfDeposit': '總供應量',
  'home.market.table.title.amountOfBorrowing': '總借款量',
  'home.market.table.title.remainingrCanBorrow': '剩餘可借',
  'home.market.table.title.openMortgage': '開啟抵押',
  'home.market.table.title.operation': '操作',
  'home.market.table.title.marketBalance': '市場可用餘額',
  'home.market.table.title.tooltips.annualizedReturnOnDeposits': '存款年化收益',
  'home.market.table.title.tooltips.interestOnBorrowings': '借款利息',
  'home.market.table.title.tooltips.annualizedEarningsFromMining':
    '挖礦年化收益',
  'home.market.table.title.tooltips.pledgeTips':
    '您尚未授權抵押，需先開啟抵押才能借款',
  'home.market.table.title.tooltips.openPledge': '去開啟抵押',

  // 我的页面
  'me.MyAssets': '我的資產',
  'me.IncomeReceived': '待領取收益',
  'me.depositAmount': '存款',
  'me.loanAmount': '借款',
  'me.Retrieve': '取回',
  'me.repayment': '還款',
  'me.ReceiveIncome': '領收益',
  'me.ReceiveIncoming': '領取中',
  'me.OneClickCollection': '一鍵領取',
  'me.health.currentHealth': '當前健康度',
  'me.health.clearingLine': '接近清算線，可通過存款或還款提高健康度',
  'me.health.keepGoing': '請繼續保持',
  'me.health.noMintAndBorrow': '您暫未存款借款',
  'me.health.warningLine': '接近警告線，可通過存款或還款提高健康度',
  'me.health.safetyValueReaches': '安全值到達1時觸發清算',
  'me.health.redReaches': '當健康度達到紅色時，請注意您的資產有被清算的風險',
  'me.health.yellowReaches': '當健康度達到黃色時，您的資產健康度為中等健康狀態',
  'me.health.greenReaches': '當健康度達到綠色時,您的資產健康度為健康狀態',
  'me.transaction.depositList': '我的存款',
  'me.transaction.loanList': '我的借款',
  'me.transaction.liquidationList': '我的清算',
  'me.transaction.notMortgaged': '您的資產列表裡面有尚未開啟抵押的資產',
  'me.transaction.openMortgagedHealth': '開啟抵押可以提高健康度',
  'me.transaction.Saved': '已存',
  'me.transaction.RepayableLoan': '應還借款',
  'me.transaction.LoanFunds': '借款資金',
  'me.transaction.MyShare': '我的份額',
  'me.transaction.UserPoolAssets': '用戶在幣種市場借款資產',
  'me.transaction.TotalLoanableAssets': '總幣種市場可借資產',
  'me.transaction.LiquidationAssets': '清算資產',
  'me.transaction.RepaymentAmount': '還款金額',
  'me.transaction.result': '結果',
  'me.transactionRecord': '交易記錄',
  'me.record.NumberOperations': '數額',
  'me.record.time': '時間',
  'me.record.ViewBrowser': '在區塊瀏覽器查看詳情',

  // Footer
  'footer.slogon': 'TrustIn 借貸行業革命型產品',
  'footer.helpLinks.viewDocument': '查看文檔',
  'footer.helpLinks.auditReport': '審計報告',
  'footer.helpLinks.about': '關於我們',
  'footer.helpLinks.email': '郵箱',

  'connectModal.haveRead': '我已閱讀並同意 ',
  'connectModal.userProtocal': '用戶協議 ',
  'connectModal.errorTip': '您需先勾選協議',
  'connectModal.desc': '您使用和訪問本網站的風險由您自行承擔',

  walletBalance: '錢包餘額',
  remainCanBorrow: '剩餘可借',
  'moneyInput.maxSafeValue': '最大安全值',
  reachToBottom: '已經到底啦',
  loading: '正在努力加載',
  fulfillPotential: '釋放資產的全部潛能',
  moneyMarket: '在數十億美元的貨幣市場',

  // 要新翻譯的詞條
  mortgageRemind:
    '您的資產列表裡面有尚未開啟抵押的資產，開啟抵押可以提高健康度',
  'market.modal.paused.thisMarketHasPaused': '该币种已暂停操作',

  'calculator.title': '計算器',
  'calculator.calculate': '测算',
  'calculator.toOperate': '去操作',
  'calculator.desc':
    '根據最新匯率計算，所有數字僅為方便您而提供的估計值，不代表保證的回報。',
  'healthCalculator.title': '健康度計算器',
  'healthCalculator.desc':
    '調整資產的數量和價格，看看您的健康度評分和收到的影響。',
  'healthCalculator.realDeposit': '真實存款',
  'healthCalculator.realBorrows': '真實借款',
  'healthCalculator.mockDeposit': '模擬存款',
  'healthCalculator.mockRepay': '模擬還款',
  'healthCalculator.myDeposits': '我的存款',
  'healthCalculator.myBorrows': '我的借款',
  'healthCalculator.addMockDeposit': '增加模擬存款',
  'healthCalculator.addMockRepay': '進行模擬還款',
  'healthCalculator.table.assets': '幣種',
  'healthCalculator.table.depositAmount': '存款数量',
  'healthCalculator.table.borrowAmount': '借款数量',
  'healthCalculator.table.price': '價格',
  'healthCalculator.table.value': '價值',
  'healthCalculator.mockRepayDesc': '可通過減少借款數量模擬還款',

  // 引导页
  'redirect.to.test.thanks': '感謝您的參與',
  'redirect.to.test.on-the-road': '當前正在部署主網',
  'redirect.to.test.experience-button': '體驗測試網',

  // 二期新增
  'home.farming': '農場',
  'home.liquidity-mining': '農場',
  'home.govern': '治理',

  pledge_v: '質押',
  retrieve_v: '取回',

  'liquidity-mining.overview.tvl': '質押TVL',
  'liquidity-mining.overview.released': '已發放獎勵',
  'liquidity-mining.overview.average-apy': '平均APY',
  'liquidity-mining.overview.my-pledge': '我的質押',
  'liquidity-mining.overview.all-earnings': '總收益',
  'liquidity-mining.modal.label.apy': '年化利率',
  'liquidity-mining.modal.pledge.label': '預估年化收益',
  'liquidity-mining.modal.withdraw.label': '每日收益將減少',
  'liquidity-mining.modal.button.pledge': '立即質押',
  'liquidity-mining.modal.button.pledging': '質押中',
  'liquidity-mining.modal.button.retrieve': '立即取回',
  'liquidity-mining.modal.button.retrieving': '取回中',
  'liquidity-mining.modal.button.get-lp': '獲取LP代幣',
  'liquidity-mining.modal.button.claim': '立即領取',
  'liquidity-mining.modal.button.target-pool': '對應礦池',
  'liquidity-mining.modal.button.all-pool': '全部參與礦池',
  'liquidity-mining.modal.claim-reward.warning-text':
    '当前网络手续费可能大于您要领取的收益，请谨慎操作',
  'liquidity-mining.table.pool-name': '礦池名稱',
  'liquidity-mining.table.current-tvl': '當前TVL',
  'liquidity-mining.table.look-for-contract': '查看合約',
  'liquidity-mining.table.look-for-contract.my-pledge': '我的質押',
  'liquidity-mining.table.look-for-contract.get': '獲取',
  'me.my-deposit-borrow': '我的借貸',
  'me.my-liquidity': '我的農場',
  'me.my-liquidity-list.pool-name': '礦池名稱',
  'me.my-liquidity-list.my-pledge': '我的質押',
  'me.my-liquidity-list.pledge': '質押',
  'me.assets.flow': '資金流水',
  'me.flow.deposit-borrow': '存借款流水',
  'me.flow.pledge-farming': '質押挖礦流水',
  'govern.on-road': 'DAO正在路上，敬請期待',

  // 二期再次新增
  'liquidity-mining.overview.my-all-earnings': '我的累計收益',
  'liquidity-mining.modal.withdraw.pledge-amount': '質押數量',
  inputValueGreaterThanPledgeAmount: '輸入金額大於質押數量',
  'liquidity-mining.modal.text.pool-has-paused': '該質押池已暫停',
  'liquidity-mining.modal.text.given-lp-get-lp': '請您提供流動性，獲取LP代幣',
  'liquidity-mining.modal.text.no-lp-go-to-pledge': '您尚未質押LP，請先質押',
  'liquidity-mining.modal.text.no-reward-go-to-pledge':
    '您還沒有獎勵，快去質押吧',
  'liquidity-mining.overview.apy.tips':
    '根據累計發放的ZNT獎勵與當前TVL進行加權得出平均APY',
  'liquidity-mining.table.apy.tips':
    '流動性挖礦APY+DEX交易手續費APY（手續費收益將直接累計至DEX流動性池中）',
  'liquidity-mining.table.pool.has-paused': '已暫停',
  'liquidity-mining.table.pool.buttons.unstake': '取回',
  'liquidity-mining.table.pool.buttons.claim': '領取收益',
};
