// 官方文档连接 https://formatjs.io/docs/core-concepts/icu-syntax#plural-format

// TODO 用繁体中文替换
export default {
  // 页面title
  'index.title': 'TrustIn',
  'index.notice.public': 'Announcement',
  'myPage.title': 'Profile',
  'home.title': 'Home',
  'me.title': 'Profile',

  // 通用文案
  day: 'day',
  days: 'days',
  year: 'year',
  connectWallet: 'Connect Wallet',
  noData: 'No Data',
  networkError: 'Network error',
  copy: 'Copy',
  all: 'All',
  copySuccess: 'Copied',
  myAddress: 'My address',
  checkInChainExplorer: 'View in {explorer}',
  disconnect: 'Disconnect',
  check: 'View',
  currency: 'Price',
  market: 'Markets',
  search: 'Search',
  mint: 'Deposit',
  redeem: 'Withdrawals',
  borrow: 'Loans',
  repay: 'Repayments',
  // 带_v表示动词
  mint_v: 'Deposit',
  redeem_v: 'Withdraw',
  borrow_v: 'Loan',
  repay_v: 'Repay',
  claim: 'Claim rewards',
  enterMarket: 'Go enable Pledge',
  exitMarket: 'Disable Pledge',
  approve: 'Authorize',
  success: 'Succeessfully',
  fail: 'Failed',
  mintRedeem: 'Deposit/Withdraw',
  borrowRepay: 'Loan/Repay',
  cancel: 'Cancel',
  calculator: 'Calculator',

  remainingInvestableIsInsufficient:
    'The input amount is higher than the available balance.',
  remainingInvestableIsInsufficient1:
    'The input amount is higher than the available balances in the markets.',
  remainingInvestableIsInsufficient2:
    'The input amount is higher than the loanable.',
  remainingInvestableIsInsufficient3:
    'The input amount is higher than the deposited.',
  theInputAmountExceedsTheMaximumSafeValue:
    'The input amount is higher than the maximum safe value.',
  remainingInvestableIsInsufficient4:
    'The input amount is higher than the loans.',
  availableBalance: 'Available balances',

  // 禁止使用彈窗
  'forbidden.modal.title': 'Reminder',
  'forbidden.modal.text':
    'Sorry. Our services are not available in your country/region for the moment.',
  'forbidden.modal.btn': 'Got it',

  // 尚未存款彈窗
  'mortgage-switch.modal.title': 'No deposits yet',
  'mortgage-switch.modal.text.normal':
    'Unable to enable pledge as you have not deposited yet.',
  'mortgage-switch.modal.text.bold': 'Please deposit first',
  'mortgage-switch.modal.deposit': 'Deposit now',
  'mortgage-switch.modal.depositing': 'Depositing',
  'mortgage-switch.modal.withdraw': 'Withdraw now',
  'mortgage-switch.modal.withdrawing': 'Withdrawing',
  'mortgage-switch.modal.borrow': 'Borrow now',
  'mortgage-switch.modal.borrowing': 'Borrowing',
  'mortgage-switch.modal.repay': 'Repay now',
  'mortgage-switch.modal.repaying': 'Repaying',

  // 确认关闭抵押弹窗
  'market.modal.mortgage-close-modal.content1':
    'After disabling Pledge, your health ratio will drop to {healthValue}, which may trigger liquidation.',
  'market.modal.mortgage-close-modal.content2':
    'Do you confirm disabling Pledge?',
  'market.modal.mortgage-close-modal.confirm': 'Disable',
  'market.modal.mortgage-close-modal.closing': 'Disabling',

  // 健康度低于1，无法关闭弹窗
  'market.modal.mortgage-can-not-close-modal.content':
    'The health is too low after cancelling the mortgage. Currently, cancelling the mortgage is not supported',

  // Meket操作彈窗
  'market.modal.residue': 'Loanable',
  'market.modal.totalCash': 'Available Markets Balance',
  'market.modal.myDeposited': 'My Deposits',
  'market.modal.estimatedInterestOnBorrowing': 'Estimated loan interests',
  'market.modal.healthChange': 'Health ratio change',
  'market.modal.estimatedAnnualizedReturnOnDeposits':
    'Est. annualized earnings',
  'market.modal.estimatedDailyDecreaseInEarningsAfterWithdrawal':
    'Est. daily decrement after withdrawal',
  'market.modal.liquidationText':
    'Caution! Your input amount may trigger liquidation. Click on [Got it] to continue.',
  'market.modal.iHaveKnown': 'Got it',

  // 授权操作
  'market.modal.approve.operationAfterAuthorization': 'Authorization needed',
  'market.modal.approve.approving': 'Authorizing',

  // 首页
  'home.overview.tvl': 'TVL',
  'home.overview.amountOfDeposit': 'Total Deposits',
  'home.overview.amountOfBorrowing': 'Total Loans',
  'home.overview.evenTheFluxOfTaiwanDollar': 'Amount In Circulation',
  'home.market.whichIAmInvolved': 'My',
  'home.market.table.title.assets': 'Assets',
  'home.market.table.title.depositApy': 'Deposits APY',
  'home.market.table.title.leanApy': 'Loans APY',
  'home.market.table.title.myLoans': 'My loans',
  'home.market.table.title.amountOfDeposit': 'Total Supplies',
  'home.market.table.title.amountOfBorrowing': 'Total Loans',
  'home.market.table.title.remainingrCanBorrow': 'Loanable',
  'home.market.table.title.openMortgage': 'Enable Pledge',
  'home.market.table.title.operation': 'Actions',
  'home.market.table.title.marketBalance': 'Market Balance',
  'home.market.table.title.tooltips.annualizedReturnOnDeposits':
    'Est. annualized earnings',
  'home.market.table.title.tooltips.interestOnBorrowings': 'Loan interests',
  'home.market.table.title.tooltips.annualizedEarningsFromMining': 'Mining APY',
  'home.market.table.title.tooltips.pledgeTips':
    "You haven't enabled Pledge yet. Enable Pledge before borrowing.",
  'home.market.table.title.tooltips.openPledge': 'Go enable Pledge',

  // 我的页面
  'me.MyAssets': 'My Assets',
  'me.IncomeReceived': 'Claimable',
  'me.depositAmount': 'Deposits',
  'me.loanAmount': 'Loaned',
  'me.Retrieve': 'Withdrawal',
  'me.repayment': 'Repayment',
  'me.ReceiveIncome': 'Claim earnings',
  'me.ReceiveIncoming': 'Claiming',
  'me.OneClickCollection': 'Claim',
  'me.health.currentHealth': 'Current Health Ratio',
  'me.health.clearingLine':
    'Approaching liquidation level. Deposit or repay now to improve health ratio.',
  'me.health.keepGoing': 'Keep it up',
  'me.health.noMintAndBorrow': 'You have no deposits or loans',
  'me.health.warningLine':
    'Approaching warning level. Deposit or repay now to improve health ratio.',
  'me.health.safetyValueReaches':
    'Liquidation will be triggered when the health ratio drops to 1.',
  'me.health.redReaches':
    'When the bar turns red, your assets are at the risk of being liquidated.',
  'me.health.yellowReaches':
    'When the bar turns yellow, your assets are in moderate health status.',
  'me.health.greenReaches':
    'When the bar turns green, your assets are in healthy status.',
  'me.transaction.depositList': 'My Deposits',
  'me.transaction.loanList': 'My Loans',
  'me.transaction.liquidationList': 'My Liquidation',
  'me.transaction.notMortgaged':
    'You have assets on which the Pledge can be enabled.',
  'me.transaction.openMortgagedHealth':
    'Enable Pledge to improve health ratio.',
  'me.transaction.Saved': 'Deposited',
  'me.transaction.RepayableLoan': 'Payable loans',
  'me.transaction.LoanFunds': 'Loaned',
  'me.transaction.MyShare': 'My Share',
  'me.transaction.UserPoolAssets': 'Loaned Assets from the Markets',
  'me.transaction.TotalLoanableAssets': 'Mining pool loanable',
  'me.transaction.LiquidationAssets': 'Liquidation',
  'me.transaction.RepaymentAmount': 'Repayment',
  'me.transaction.result': 'Result',
  'me.transactionRecord': 'Transaction records',
  'me.record.NumberOperations': 'Amount',
  'me.record.time': 'Time',
  'me.record.ViewBrowser': 'View details in blockchain explorer',

  // Footer
  'footer.slogon': 'TrustIn, revolutionizes the lending practice',
  'footer.helpLinks.viewDocument': 'View docs',
  'footer.helpLinks.auditReport': 'Audit Report',
  'footer.helpLinks.about': 'About Us',
  'footer.helpLinks.email': 'Email',

  'connectModal.haveRead': 'I have read and agree to ',
  'connectModal.userProtocal': 'User Agreement ',
  'connectModal.errorTip': 'Please tick the checkbox first',
  'connectModal.desc':
    'The risks relating to visiting and using this website lie with you.',

  walletBalance: 'Wallet balance',
  remainCanBorrow: 'Loanable',
  'moneyInput.maxSafeValue': 'Max Safe Value',
  reachToBottom: 'End of page',
  loading: 'Loading',
  fulfillPotential: 'Fulfill true potential ',
  moneyMarket: 'of your assets in billions USD money market',
  // 要新翻譯的詞條
  mortgageRemind:
    "You have assets on which the pledges haven't been enabled yet. You may improve the Health Ratio by enabling pledges on those assets.",
  'market.modal.paused.thisMarketHasPaused':
    'Not availble for this token for the moment.',
  'calculator.title': 'Calculator',
  'calculator.calculate': 'Calculate',
  'calculator.toOperate': 'Operate',
  'calculator.desc':
    'The estimated figures are calculated based on the latest conversion rates and are for reference only. They do not represent guaranteed returns.',
  'healthCalculator.title': 'Health Ratio Calculator',
  'healthCalculator.desc':
    'Adjust the amount and price to see how they affect the Health Ratio and what are the consequences.',
  'healthCalculator.realDeposit': 'Real Deposits',
  'healthCalculator.realBorrows': 'Real Loans',
  'healthCalculator.mockDeposit': 'Mock Deposit',
  'healthCalculator.mockRepay': 'Mock Repayment',
  'healthCalculator.myDeposits': 'My Deposits',
  'healthCalculator.myBorrows': 'My Loans',
  'healthCalculator.addMockDeposit': 'Add mock deposit',
  'healthCalculator.addMockRepay': 'Add mock repayment',
  'healthCalculator.table.assets': 'Token',
  'healthCalculator.table.depositAmount': 'Deposited',
  'healthCalculator.table.borrowAmount': 'Loaned',
  'healthCalculator.table.price': 'Price',
  'healthCalculator.table.value': 'Value',
  'healthCalculator.mockRepayDesc':
    'You may mock the repayment by reducing loan amount.',

  // 引导页
  'redirect.to.test.thanks': 'Thanks for your participation',
  'redirect.to.test.on-the-road': 'Mainnet is being deployed',
  'redirect.to.test.experience-button': 'Explore the Testnet',

  // 要新翻譯的詞條
  'home.farming': 'Farms',
  'home.liquidity-mining': 'Farms',
  'home.govern': 'Governance',
  pledge_v: 'Stake',
  retrieve_v: 'Withdraw',
  'liquidity-mining.overview.tvl': 'Staking TVL',
  'liquidity-mining.overview.released': 'Distributed Rewards',
  'liquidity-mining.overview.average-apy': 'Average APY',
  'liquidity-mining.overview.my-pledge': 'My Staked Assets',
  'liquidity-mining.overview.all-earnings': 'Total earnings',
  'liquidity-mining.modal.label.apy': 'APY',
  'liquidity-mining.modal.pledge.label': 'Est. Annual Return',
  'liquidity-mining.modal.withdraw.label': 'Daily returns will decrease by',
  'liquidity-mining.modal.button.pledge': 'Stake Now',
  'liquidity-mining.modal.button.pledging': 'Staking',
  'liquidity-mining.modal.button.retrieve': 'Withdraw Now',
  'liquidity-mining.modal.button.retrieving': 'Withdrawing',
  'liquidity-mining.modal.button.get-lp': 'Get LP tokens',
  'liquidity-mining.modal.button.claim': 'Claim Now',
  'liquidity-mining.modal.button.target-pool': 'Target pool',
  'liquidity-mining.modal.button.all-pool': 'All pools you participated in',
  'liquidity-mining.modal.claim-reward.warning-text':
    'The current network gas fee may be greater than the income you will receive, please proceed with caution.',
  'liquidity-mining.table.pool-name': 'Pool',
  'liquidity-mining.table.current-tvl': 'Current TVL',
  'liquidity-mining.table.look-for-contract': 'View Contract',
  'liquidity-mining.table.look-for-contract.my-pledge': 'My Staked Assets',
  'liquidity-mining.table.look-for-contract.get': 'Get',
  'me.my-deposit-borrow': 'My Loan',
  'me.my-liquidity': 'My Farms',
  'me.my-liquidity-list.pool-name': 'Pool',
  'me.my-liquidity-list.my-pledge': 'My Staked Assets',
  'me.my-liquidity-list.pledge': 'Stake',
  'me.assets.flow': 'Cash Flow',
  'me.flow.deposit-borrow': 'Transaction Records',
  'me.flow.pledge-farming': 'LP Farming Records',
  'govern.on-road': 'DAO is coming soon. Please stay tuned',

  'liquidity-mining.overview.my-all-earnings': 'My Total Earnings',
  'liquidity-mining.modal.withdraw.pledge-amount': 'Amount staked',
  inputValueGreaterThanPledgeAmount:
    'The amount you entered is greater than the amount staked',
  'liquidity-mining.modal.text.pool-has-paused':
    'The staking pool has been suspended',
  'liquidity-mining.modal.text.given-lp-get-lp':
    'Please provide liquidity to get LP tokens',
  'liquidity-mining.modal.text.no-lp-go-to-pledge':
    'Please stake LP tokens first',
  'liquidity-mining.modal.text.no-reward-go-to-pledge':
    'No rewards yet. Stake now',
  'liquidity-mining.overview.apy.tips':
    'Average APY is obtained by the weighted average calculation based o the cumulative ZNT rewards distributed and the current TVL',
  'liquidity-mining.table.apy.tips':
    'Farming APY + Swap Fee APY (Trading fee earnings will be directly accumulated into the DEX liquidity pool)',
  'liquidity-mining.table.pool.has-paused': 'Suspended',
  'liquidity-mining.table.pool.buttons.unstake': 'Unstake',
  'liquidity-mining.table.pool.buttons.claim': 'Claim',
};
