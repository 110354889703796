import { getLocale } from 'umi';

import 'antd/dist/antd.css';
import './global.less';

export function render(oldRender: () => any) {
  // do something before render
  oldRender();
}

export async function getInitialState() {
  return {
    theme: 'dark',
  };
}

export const locale = {
  setLocale({
    lang,
    realReload,
    updater,
  }: {
    lang: string;
    realReload: boolean;
    updater: any;
  }) {
    const curLocale = getLocale();
    if (curLocale === locale) return;
    document.body.classList.remove(curLocale);
    document.body.classList.add(lang);
    updater();
  },
};
