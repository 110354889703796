// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/haiifeng/bitlayer/lending-fe-web/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx')}),
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx')})
      },
      {
        "path": "/farming",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__farming__index' */'@/pages/farming/index.tsx')}),
        "title": "home.liquidity-mining"
      },
      {
        "path": "/govern",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__govern__index' */'@/pages/govern/index.tsx')}),
        "title": "home.govern"
      },
      {
        "path": "/",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index__index' */'@/pages/index/index.tsx')}),
        "title": "index.title"
      },
      {
        "path": "/me",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__me__index' */'@/pages/me/index.tsx')}),
        "title": "myPage.title"
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx')})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
